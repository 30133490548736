/* Application Theme Style */
:root {
  --bannerDarkMode: #2192FF;

  --sidebarDarkMode: #e9ecef;

  --contentAreaDarkMode: #f8f9fa;
  --sidebarItemOverDarkMode: #fff;
  --sidebarItemColor: #000;
  --appMainTitle: #FFF;
  --logoTextColor: #0ead69;
  --subItemTextOverColor: #ff3f00;
  --iconsColor: #000;
  --toggleButtonColor: #fff;
  --footerBackground: #a2d6f9;
  --footerColor: #000;
}

.dark-theme {
  --bannerDarkMode: #26282B;
  --sidebarDarkMode: #171717;
  --contentAreaDarkMode: #171717;
  --sidebarItemOverDarkMode: #1a1b1d;
  --sidebarItemColor: #FFF;
  --appMainTitle: #cae9ff;
  --logoTextColor: #cae9ff;
  --subItemTextOverColor: #ffe6a7;
  --iconsColor: #cae9ff;
  --toggleButtonColor: #cae9ff;
  --footerBackground: #353535;
  --footerColor: #fff;
}

/* Application Body Style */
* {
  margin: 0px;
  padding: 0px;
  font-family: Helvetica;
}

/* Application Main Layout Style */
.mainWrapper {
  display: grid;
  background: var(--sidebarDarkMode);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 70px auto auto;
  gap: 0px;
}

/* Applicaiton Top Banner Style  */
#topBanner {
  width: 100%;
  height: 80px;
  background-color: #2196f3;
  grid-column: span 8;
  grid-row: 1;
}

#logoContainerLink {
  text-decoration: none;
}

#logo {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  position: relative;
  top: 5px;
  left: 20px;

}

#templateTitle {
  position: relative;
  bottom: 5px;
  left: 20px;
  font-size: 22px;
  font-weight: bolder;
  color: var(--appMainTitle);
}

.btnRegister {
  padding: 10px;
  border-radius: 20px;
  float: right;
  text-decoration: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  top: 15px;
  right: 5px;
  color: #e6e6ea;
}

.btnLogin {
  padding: 10px;
  border-radius: 20px;
  float: right;
  text-decoration: none;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  top: 15px;
  left: 20px;
  color: #e6e6ea;

}

.btnLoginRegisterSeparator {
  padding: 10px;
  float: right;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  top: 15px;
  left: 8px;
  color: #e6e6ea;
}

.btnRegister:hover,
.btnLogin:hover {
  color: #c42021;
}

.btnRegister:active,
.btnLogin:active {
  color: #0d47a1;
}

.txtSearch {
  position: relative;
  border-radius: 20px 0px 0px 20px !important;
  padding: 10px !important;
  font-size: 18px !important;
  width: 500px !important;
  float: right;
  border: none !important;
}

.btnSearch {
  position: relative;
  background-color: #e2e2e2;
  font-weight: bold;
  float: right;
  padding: 10px;
  right: 1px;
  border-radius: 0px 20px 20px 0px !important;
  border: none !important;
  cursor: pointer;
}

.btnSearch:hover {
  background-color: #ced0ce;
}

.txtSearch:focus {
  box-shadow: none !important;
}

.search-box-container {
  float: right;
  height: 36px;
  position: relative;
  top: 18px;
  display: inline-block;
}


/* Application Menu Style */
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

header {
  background-color: #90caf9;
  grid-column: span 8;
  grid-row: 2;
  position: sticky;
  z-index: 1000;
  top: 0;
  box-shadow: 0px 1px 0px 0px #d6d6d6;
}

.container {
  padding: 0 1.5rem;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.nav-links>ul {
  display: flex;
  justify-content: left;
}

.nav-link {
  position: relative;
}

.nav-link>a {
  line-height: 3rem;
  color: #000000;
  padding: 0 .8rem;
  letter-spacing: 1px;
  font-size: .95rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: .5s;
  font-weight: bold;
}

.nav-link>a>i {
  margin-left: .2rem;
}


.nav-link:hover>a {
  transform: scale(1.1);
  color: #c42021;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 18rem;
  transform: translateY(20px);
  opacity: 0;
  pointer-events: none;
  transition: .3s;
}

.dropdown ul {
  position: relative;
}

.dropdown-link>a {
  display: flex;
  background-color: #bbdefb;
  color: #000000;
  padding: .6rem 1rem;
  font-size: .9rem;
  align-items: center;
  justify-content: space-between;
  transition: .3s;
  box-shadow: 2px 2px 4px #24272b;
}

.dropdown-link:hover>a {
  background-color: #2196f3;
  color: #fff;
}

.dropdown-link i {
  transform: rotate(-90deg);
}

.arrow {
  position: absolute;
  width: 11px;
  height: 11px;
  top: -5.5px;
  left: 32px;
  background-color: #bbdefb;
  transform: rotate(45deg);
  cursor: pointer;
  transition: .3s;
  z-index: -1;
}

.dropdown-link:first-child:hover~.arrow {
  background-color: #2196f3;
}

.dropdown-link {
  position: relative;
}

.dropdown.second {
  top: 0;
  left: 100%;
  padding-left: .8rem;
  cursor: pointer;
  transform: translateX(10px);
}

.dropdown.second .arrow {
  top: 10px;
  left: -5.5px;
}

.nav-link:hover>.dropdown,
.dropdown-link:hover>.dropdown {
  transform: translate(0, 0);
  opacity: 1;
  pointer-events: auto;
}

.web-menu-container {
  flex: 1;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

.web-menu {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.web-menu div {
  width: 1.6rem;
  height: 3px;
  border-radius: 3px;
  background-color: #fff;
  position: relative;
  z-index: 1001;
  transition: .5s;
}

.web-menu div:before,
.web-menu div:after {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: #fff;
  border-radius: 3px;
  transition: .5s;
}

.web-menu div:before {
  transform: translateY(-7px);
}

.web-menu div:after {
  transform: translateY(7px);
}

#check {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  z-index: 90000;
  cursor: pointer;
  opacity: 0;
  display: none;
}

#check:checked~.web-menu-container .web-menu div {
  background-color: transparent;
}

#check:checked~.web-menu-container .web-menu div:before {
  transform: translateY(0) rotate(-45deg);
  background-color: #e71d36;
}

#check:checked~.web-menu-container .web-menu div:after {
  transform: translateY(0) rotate(45deg);
  background-color: #e71d36;
}



/* Application Sidebar Style  */
#contentSidebar {
  grid-column-start: span 1;
  grid-row: 3;
  background-color: var(--sidebarDarkMode);
  height: 100%;
  position: relative;
  width: 100%;
  transition: 100ms linear;
  border-right: 1px solid #d6d6d6;
}

#subjectName {
  font-size: 18px;
  font-weight: bold;
  display: table;
  text-align: center;
  display: table;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 5px;
  width: 90%;
  border-bottom: 2px dotted #6c757d;
}

#contentSidebar #topicTitle a {
  text-decoration: none;
}

#contentSidebar #topicTitle li {
  padding: 10px;
  left: 10px;
  font-size: 16px;
  color: #000000;
}

#contentSidebar #topicTitle li:hover {
  background-color: #bbdefb;
  color: #0496ff;
}


/* Application Content Area Style  */
.contentArea {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row: 3;
  background-color: var(--contentAreaDarkMode);
  padding: 20px 10px 20px 10px;
  text-align: justify;
  height: 100%;
}

.comment-form-container {
  border-top: 5px solid #2196f3;
  border-bottom: 5px solid #2196f3;
  background: var(--contentAreaDarkModeSubContainerTheme);
  padding: 10px;
  position: relative;
  margin-top: 100px;
  margin-bottom: 10px;
  border-radius: 0px;
  box-shadow: 1px 1px 2px #9999a1;
  width: calc(100% - 20px);
}

.comment-image-wrapper {
  margin: 20px auto;
  float: left;
  border-bottom: 2px solid #ced4da;
  padding: 5px;
  padding-bottom: 10px;
}

.comment-image-wrapper:hover {
  background-color: #dee7e7;
  border-radius: 5px;
  cursor: pointer;

}

.comment-image-wrapper img {
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 10px;
}

.comment-image-wrapper b {
  position: relative;
  display: block;
  top: 30px;
}

.comment-image-wrapper span {
  position: relative;
  display: block;
  line-height: 2;
  top: 30px;
  color: #0a85ed;
}

.comment-image-wrapper p {
  float: left;
}


/* Application Advertisment Sidebar Style  */
#advertiseSidebar {
  grid-column-start: 5;
  grid-row: 3;
  background-color: var(--contentAreaDarkMode);
  width: 180px;
  height: 100%;
  transition: 100ms linear;
  border-left: 1px solid #e7ecef;
  padding: 5px;
}

/* Application Subjects Footer Style */
#subjectFooter {
  grid-column: span 8;
  grid-row: 4;
  background: #0d3b66;
  padding: 10px;
  color: var(--footerColor);
  min-height: 100px;
  text-align: center;
}

#subjectFooter ul {
  list-style-type: none;
  font-size: 18px;
}

#subjectFooter ul li {
  display: inline-flex;
}

#subjectFooter ul li a {
  padding: 8px;
  background-color: #0d3b66;
  text-decoration: none;
  border-radius: 5px;
  margin: 3px;
  color: #faf0ca;
  border: 1px solid #0d3b66;

}

#subjectFooter ul li a:hover {

  color: #f9dc5c;
  border: 1px solid #f9dc5c;
}

#subjectFooter ul li a:active {

  color: #fbf8cc;

}

/* Application Useful Links Footer Style */
#usefulLinksFooterContainer {
  grid-column: span 8;
  grid-row: 5;
  background: #eaf4f4;
  padding: 10px;
  color: var(--footerColor);
}

#usefulLinksFooter {
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-flow: wrap;
  padding-top: 50px;
  padding-bottom: 50px;
}

#useFullLinksSubContainer {
  width: 33%;
}

#useFullLinksSubContainer p {
  position: relative;
  line-height: 2;
  color: #000000;
  left: 10px;
}

#useFullLinksSubContainer ul li a {
  position: relative;
  line-height: 2;
  color: #000000;
  left: 10px;
}

#useFullLinksSubContainer ul li a:hover {
  color: #2196f3;
}

#useFullLinksSubContainer ul li a:active {
  color: #dd1c1a;
}

#useFullLinksSubContainer .shareLinks {
  display: inline-flex;
  position: relative;
  background-color: #2196f3;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  top: 10px;
  margin-left: 5px;
  cursor: pointer;
}

#useFullLinksSubContainer .shareLinks:hover {

  background-color: #2667ff;
  box-shadow: 2px 2px 4px #d6d6d6;
}

#useFullLinksSubContainer .shareLinks:active {
  background-color: #0d47a1;
}

#useFullLinksSubContainer .icon-color {
  position: relative;
  top: 8px;
  left: 7px;
  color: #ffffff;
}



/* Application Bottom Footer Style  */
.footer {
  grid-column: span 8;
  grid-row: 6;
  background: var(--footerBackground);
  padding: 20px;
  color: var(--footerColor);
  text-align: left;
}

.footer .copyRight {
  display: inline;
  position: relative;
  top: 14px;
}

.top {
  position: sticky;
  float: right;
  text-decoration: none;
  padding: 7px 12px;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  background: #1565c0;
  border-radius: 3px;
}

.top:hover {
  background-color: #0d47a1;
}

html,
body {
  scroll-behavior: smooth;
}



/*===========================================================
================ Contact Form  Start Style ==================
===========================================================*/
.contactFormContainer {
  border-top: 5px solid #2196f3;
  border-bottom: 5px solid #2196f3;
  background: var(--contentAreaDarkModeSubContainerTheme);
  padding: 10px;
  position: relative;

  border-radius: 0px;
  box-shadow: 1px 1px 2px #9999a1;
  width: 80%;
  display: table;
  margin: 0 auto;
}

.formRow {
  margin-top: 10px;
  margin-bottom: 10px;
}

.formRow .formGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 20px;
}

.formRow .formGroup input {
  width: calc(100% - 10px);
  border-radius: 5px;
}

.formRow .formGroup .btnSendMessage {
  margin-top: 20px;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  background: #1e88e5;
  border: none;
  color: #FFF;
  font-size: 16px;
}

.formRow .formGroup .btnSendMessage:hover {
  background: #2196f3;
}

.formRow .formGroup .btnSendMessage:active {
  background: #1e88e5;
}

input[type=text],
[type=number],
[type=date],
[type=email],
[type=file],
[type=password] {
  border: 1px solid #000;
  border-color: #000000;
  border-radius: 0px;
  height: 35px;
  width: 300px;
  display: block;
  margin: 0 auto;
  padding: 5px;
  margin-bottom: 20px;
  font-size: 16px;

}

input[type=text]:focus,
[type=number]:focus,
[type=date]:focus,
[type=email]:focus,
[type=file]:focus,
[type=password]:focus {
  border-color: #3a86ff;
  box-shadow: none;
  box-shadow: 0 0 0 0.5pt #D3D3D3;
  outline: none;
}


textarea {
  border-color: #000000 !important;
  border-radius: 5px !important;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 10px;
  font-size: 16px;
  outline: none;
}

textarea:focus {
  box-shadow: 0 0 0 0.5pt #D3D3D3 !important;
  border: 1px solid #3a86ff !important;
}


/*===========================================================
=================== Home Page Form Style ====================
===========================================================*/
.homePageDetails {
  background: #1d2d44;
  height: 300px;
  width: 100%;
  position: relative;

}

.homePageSubject {
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-flow: wrap;
  margin-bottom: 20px;
}

.box1 {
  background: #e9ecef;
  width: 48.3%;
  height: 200px;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  box-shadow: 0px 0px 2px #6c757d;
  transition: 1s;
}

.box1:hover {
  transition: 1ms;
  box-shadow: 2px 2px 4px #6c757d;

}

.subjectTitle {
  width: 100%;
  display: inline-block;
  background: #dee2e6;
  border-radius: 5px 5px 0px 0px;
  color: #000;
  font-size: 28px;
  padding: 5px 0px;
  font-weight: bold;
}

.subjectLink {
  padding: 10px 20px;
  background-color: #ff7900;
  border-radius: 30px;
  font-size: 24px;
  position: relative;
  top: 100px;
  text-decoration: none;
  color: #fff;
}

.subjectLink:hover {
  background-color: #ff6d00;
}

.subjectLink:active {
  background-color: #ff7900;
}

.keywords {
  fill: #24272b;
  stroke: #000;
  font-weight: 800;
  font-size: 36px;
}



/*===========================================================
================== About Page Start Style ===================
===========================================================*/

.aboutName {
  display: table;
  margin: 0 auto;
  line-height: 2;
  color: var(--contentAreaTextDarkModeColor);
}

.aboutJob {
  display: table;
  margin: 0 auto;
  color: #59a5d8;
}

.aboutImage {
  display: table;
  margin: 0 auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  top: 70px;
  filter: brightness(50%);
  transform: skew(-5deg);
}

.aboutImage:hover {
  filter: brightness(100%);
  transition: 1s linear;
}

.aboutInformation {
  display: table;
  margin: 0 auto;
  box-shadow: 0px 0px 1px #696773;
  padding: 90px 30px 30px 30px;
  border-radius: 5px;
  width: 80%;
  background: var(--contentAreaDarkModeSubContainerTheme);
  color: var(--contentAreaTextDarkModeColor);
}

.aboutInformation p {
  text-align: justify;
  font-size: 18px;

  padding-bottom: 40px;
}


/*===========================================================
====================== Animation Style ======================
===========================================================*/
@keyframes animation {
  from {
    opacity: 0;
    transform: translateY(15px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/*===========================================================
====================== Responsive Style =====================
===========================================================*/
@media (max-width: 920px) {

  .web-menu-container {
    display: flex;
    top: 30px;
  }

  #check {
    display: block;
  }

  .nav-btn {
    position: absolute;
    height: calc(100vh - 3rem);
    left: 0;
    width: 100%;
    background-color: #90caf9;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
    transition: .65s;
  }

  #check:checked~.nav-btn {
    transform: translateX(0);
  }

  #check:not(:checked)~.nav-btn {
    position: fixed;
  }

  #check:checked~.nav-btn .nav-link,
  #check:checked~.nav-btn .log-sign {
    animation: animation .5s ease forwards var(--i);
  }

  .nav-links {
    flex: initial;
    width: 100%;
  }

  .nav-links>ul {
    flex-direction: column;
  }

  .nav-link {
    width: 100%;
    opacity: 0;
    transform: translateY(15px);
  }

  .nav-link>a {
    line-height: 1;
    padding: 1.6rem 2rem;
  }

  .nav-link:hover>a {
    transform: scale(1);
    background-color: #bbdefb;
  }

  .dropdown,
  .dropdown.second {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    opacity: 1;
    pointer-events: auto;
    width: 100%;
    padding: 0;
    background-color: #e3f2fd;
    display: none;
  }

  .nav-link:hover>.dropdown,
  .dropdown-link:hover>.dropdown {
    display: block;
  }

  .nav-link:hover>a>i,
  .dropdown-link:hover>a>i {
    transform: rotate(360deg);
  }

  .dropdown-link>a {
    background-color: transparent;
    color: #000000;
    padding: 1.2rem 2rem;
    line-height: 1;
    box-shadow: 0px 0px 0px 0px #d6d6d6;
    border-bottom: 1px solid #000;
  }

  .dropdown.second .dropdown-link>a {
    padding: 1.2rem 2rem 1.2rem 3rem;
  }

  .dropdown.second .dropdown.second .dropdown-link>a {
    padding: 1.2rem 2rem 1.2rem 4rem;
  }

  .dropdown-link:not(:nth-last-child(2)) {
    border-bottom: none;
  }

  .arrow {
    z-index: 1;
    background-color: #e3f2fd;
    left: 10%;
    transform: scale(1.1) rotate(45deg);
    transition: .5s;
    display: none;
  }

  .nav-link:hover .arrow {
    display: none;
  }

  .dropdown .dropdown .arrow {
    display: none;
  }

  .dropdown-link:hover>a {
    background-color: #90caf9;
  }

  .dropdown-link:first-child:hover~.arrow {
    display: none;
  }

  .nav-link>a>i {
    font-size: 1.1rem;
    transform: rotate(-90deg);
    transition: .7s;
  }

  .dropdown i {
    font-size: 1rem;
    transition: .7s;
  }

  .log-sign {
    flex: initial;
    width: 100%;
    padding: 1.5rem 1.9rem;
    justify-content: flex-start;
    opacity: 0;
    transform: translateY(15px);
  }

  #contentArea {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row: 3;
    background-color: var(--contentAreaDarkMode);
    padding: 70px 10px 10px 10px;
    min-height: 615px;
    text-align: justify;
  }

  #contentSidebar {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row: 4;
    border-right: none;

  }

  #subjectName {
    background: #b7e4c7;
    margin: 0px;
    padding: 10px;
    width: calc(100% - 20px);
  }

  #advertiseSidebar {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row: 5;
    width: calc(100% - 11px);
    padding-top: 12px;
  }

  #subjectFooter {
    grid-row: 6;
  }

  #usefulLinksFooterContainer {
    grid-row: 7;
  }

  .footer {
    grid-row: 8;
  }

  .homePageSubject {
    position: relative;
    padding-bottom: 50px;
    top: 50px;
  }

  .homePageSubject .box1 {
    width: 100%;
  }

  #usefulLinksFooter #useFullLinksSubContainer {
    width: 100%;
    padding-bottom: 25px;
  }

  .contentArea .contactFormContainer {
    width: calc(100% - 20px);
    top: 20px;
  }

  #topBanner .search-box-container {
    background-color: #dee2e6;
    width: 100%;
    top: 46px;
    height: 56px;
  }

  #topBanner .txtSearch {
    width: calc(100% - 100px) !important;
    top: 10px;
    right: 10px;
  }

  #topBanner .btnSearch {
    top: 10px;
    right: 10px;
    background-color: #90caf9;
  }

  #topBanner .btnSearch:hover {
    background-color: #64b5f6;
  }

  .contentArea svg {
    position: relative;
    top: 57px;
  }
}